import { ReactNode } from 'react'
import { Heading, Text } from 'ui-kit/typography'
import SupportSummer from './best-support-summer.svg'
import SupportSpring from './best-support-spring.svg'
import SupportFall from './best-support-fall.svg'
import SupportWinter from './best-support-winter.svg'
import clsx from 'clsx'
import classes from './Badges.module.scss'

export const BestSupportBadges = ({
  subtitle,
  className,
}: {
  subtitle?: ReactNode
  className?: string
}) => {
  const defaultSubtitle =
    'Our world-class solutions team is just one Slack message away. We help you understand complex APIs, build robust integration logic, and keep your customer integrations running smoothly'

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.heading}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Best support and services on the market
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          {subtitle || defaultSubtitle}
        </Text>
      </div>
      <div className={classes.awards}>
        <img
          src={SupportSpring.src}
          alt='Best Support spring 2024 award'
          loading='lazy'
          width={98}
          height={128}
          className={classes.image}
        />
        <img
          src={SupportSummer.src}
          alt='Best Support summer 2024 award'
          loading='lazy'
          width={98}
          height={128}
          className={classes.image}
        />
        <img
          src={SupportFall.src}
          alt='Best Support fall 2024 award'
          loading='lazy'
          width={98}
          height={128}
          className={classes.image}
        />
        <img
          src={SupportWinter.src}
          alt='Best Support winter 2025 award'
          loading='lazy'
          width={98}
          height={128}
          className={classes.image}
        />
      </div>
    </div>
  )
}
