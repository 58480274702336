import { Heading, Text } from 'ui-kit/typography'
import AwsLogoImage from './logo-aws.png'
import CertificateLogoImage from './logo-certificate.png'
import DockerLogoImage from './logo-docker.png'
import KubernetesLogoImage from './logo-kubernetes.png'
import clsx from 'clsx'
import classes from './Certification.module.scss'

export const Certification = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(classes.wrapper, className)}>
      <ul className={classes.list}>
        <li className={clsx(classes.item, classes.item__certificate)}>
          <div className={classes.card}>
            <Heading as={'h3'} size={'xs'} className={classes.card_title}>
              Certified world-class security
            </Heading>
            <Text size={'lg'} className={classes.card_description}>
              We are SOC-2 Type 2 certified, GDPR-compliant, and can address any
              of your or your customer's security concerns.
            </Text>

            <ul className={classes.card_list}>
              <li className={classes.card_item}>
                <img
                  src={CertificateLogoImage.src}
                  alt='certificate logo'
                  width={88}
                  height={88}
                  loading={'lazy'}
                />
              </li>
              <li className={classes.card_item}>
                <Text size={'lg'} lh={'loose'}>
                  SOC-2 certified
                </Text>
                <Text size={'lg'} lh={'loose'}>
                  GDPR compliant
                </Text>
              </li>
            </ul>
          </div>
        </li>

        <li className={clsx(classes.item, classes.item__deployment)}>
          <div className={classes.card}>
            <Heading as={'h3'} size={'xs'} className={classes.card_title}>
              On-premise Deployment
            </Heading>
            <Text size={'lg'} className={classes.card_description}>
              You can run our engine using Docker on your own infrastructure.
            </Text>

            <ul className={classes.card_list}>
              <li className={classes.card_item}>
                <img
                  src={AwsLogoImage.src}
                  alt='aws logo'
                  width={71}
                  height={56}
                  loading={'lazy'}
                />
              </li>

              <li className={classes.card_item}>
                <img
                  src={DockerLogoImage.src}
                  alt='docker logo'
                  width={65}
                  height={56}
                  loading={'lazy'}
                />
              </li>

              <li className={classes.card_item}>
                <img
                  src={KubernetesLogoImage.src}
                  alt='kubernetes logo'
                  width={151}
                  height={56}
                  loading={'lazy'}
                />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  )
}
