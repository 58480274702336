import { Text } from 'ui-kit/typography'
import { customerLogosData } from './customerLogosData'
import { CustomerLogosCard } from './CustomerLogosCard'
import classes from './CustomerLogos.module.scss'

export const CustomerLogos = () => {
  return (
    <div className={classes.wrapper}>
      <Text lh={'tight'} center className={classes.subtitle}>
        Trusted by 100+ SaaS companies
      </Text>

      <ul className={classes.list}>
        {customerLogosData.map((item, idx) => (
          <CustomerLogosCard key={idx} item={item} />
        ))}
      </ul>
    </div>
  )
}
