import { Heading, Text } from 'ui-kit/typography'
import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { reviewsData } from './reviewsData'
import { ReviewsCard } from './ReviewsCard'
import clsx from 'clsx'
import classes from './Reviews.module.scss'

export const BestSupportReviews = ({ className }: { className?: string }) => {
  const { slidesToRender, carouselSettings } = useCarousel(reviewsData)

  return (
    <div className={clsx('flex-column', classes.wrapper, className)}>
      <Heading as={'h3'} size={'sm'} balance center className={classes.title}>
        Don't just take our word for it
      </Heading>
      <Text size={'lg'} balance center className={classes.subtitle}>
        Hear from some of our amazing customers
      </Text>

      <Carousel className={classes.slider} {...carouselSettings}>
        {slidesToRender.map((card, index) => {
          return (
            <div className={classes.slide} key={index}>
              <ReviewsCard card={card} />
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
